.btn-group > .menu-tab {
  border-radius: 0 !important;
}

.btn-group > .menu-tab:last-child {
  border-radius: 0 0.625rem 0 0 !important;
}
.btn-group > .menu-tab:first-child {
  border-radius: 0.625rem 0 0 0 !important;
}

/* @media only screen and (max-width: 700px) {
  .btn-group > .menu-tab,
  .btn-group > .menu-tab:last-child,
  .btn-group > .menu-tab:first-child {
    border-radius: 0 !important;
  }
} */
