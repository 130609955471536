@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --mainColor: #158892;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #f3f2ef !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--mainColor);
  margin: 0;
  vertical-align: middle;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.card-effect:hover{
  box-shadow: 0px 3px 8px rgba(32, 31, 31, 0.1) !important;
}

.navbar {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  background-color: var(--mainColor) !important;
  z-index: 100 !important;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1) !important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  z-index: 1000 !important;
}

.footer-navbar {
  /* position: -webkit-sticky !important; */
  top: 100;
  position: fixed !important;
  bottom: 0 !important;
  background-color: var(--mainColor) !important;
  z-index: 100 !important;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1) !important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.list-group-item {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  padding: 5px 10px;
}

.checklist-item {
  width: 85% !important;
  display: inline-block;
  margin: 0;
}

.modal-delete-btn {
  float: right;
}
/*.progress-bar */
.progress-bar {
  background-color: var(--mainColor);
}

/*Customize tags*/
.event-title-label {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}
.event-main-title {
  display: inline-block;
  margin-bottom: 5;
  font-size: 1.25rem;
}

.event-main-dates,
.event-main-location {
  margin: 0;
}

.event-main-description {
  font-size: 12px;
  color: #606060;
  margin: 0;
  margin-bottom: 5px;
  display: block;
}

.formgroup-right-input {
  border-radius: 0rem 0.375rem 0.375rem 0rem !important;
  padding-left: 0.5rem !important;
}

.formgroup-right-date-input {
  border-radius: 0rem 0.375rem 0.375rem 0rem !important;
  padding-left: 0.5rem !important;
  border-left: 0px solid #fff !important;
}

.formgroup-left-input {
  border-radius: 0.375rem 0rem 0rem 0.375rem !important;
}

.formgroup-middle-input {
  border-radius: 0rem 0rem 0rem 0rem !important;
}

/*Plan Cards*/
.travel-plan-card-cover {
  height: 150px;
  object-fit: cover;
  object-position: 100% 25%;
}
.travel-plan-card-body {
  padding: 5px 10px;
}

.travel-plan-card-footer {
  padding: 0px;
}

.travel-plan-title,
.travel-plan-dates,
.travel-plan-location {
  font-size: 12px;
  margin: 0;
}

/*Left date picker override*/
.react-datepicker__input-container > input.formgroup-left-input,
.react-datepicker__input-container > input.formgroup-departure-input {
  padding-left: 40px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 35px;
  padding: 4px 10px;
  font-size: 1.2rem;
}

/*input group override*/
.dateSelector > div:nth-child(2) {
  width: 57%;
}
.dateSelector > div:nth-child(3) {
  width: 43%;
}

.mb-2.input-group > .input-group-append {
  width: auto !important;
}

.formgroup-departure > .input-group > div:nth-child(2) {
  width: 100% !important;
}

.react-datepicker__input-container {
  width: 100%;
}

/*Modal Ticket*/
.view-ticket {
  max-width: 80% !important;
}

@media only screen and (max-width: 800px) {
  .view-ticket {
    max-width: 100% !important;
  }
}

@media (min-width: 576px){
  .quoration-form  {
    max-width: 90vw !important;
    margin: 1.875rem auto;
}
}

/*Plan Menu Tab*/
.menu-tab-first {
  border-radius: 0.625rem 0 0 0 !important;
}

.menu-tab {
  padding: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-tab-header, .menu-tab-footer {
  padding: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 !important;
}

.menu-tab-last {
  border-radius: 0 0.625rem 0 0 !important;
}

/*Card Override*/
.main-card {
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
}

/*Menu*/
.sidebar-menu-container {
  /* box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.10); */
  z-index: 151;
  /* background-color: #fff; */
}

.main-header-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 150;
  background-color: #fff;
  /* box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1); */
}

.sidebar-menu-link {
  margin-top: 0.5rem !important;
  text-decoration: none !important;
}
.sidebar-menu-link-btn {
  text-align: left;
  margin-top: 0.5rem !important;
}

.active > .sidebar-menu-link-btn {
  background-color: var(--mainColor) !important;
  color: aliceblue;
}

/*Cover Image*/
.plan-cover-image {
  width: 100%;
  height: 200px;

  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
}

.plan-cover-image-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.603);
  /* border-radius: 0.625rem 0.625rem 0 0; */
  padding: 20px;
}

/*Avatars*/
.avatar {
  vertical-align: middle;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  right: 0;
  float: right;
  border: 2px solid var(--mainColor);
}

/*Calendar Oerride*/
.rbc-header {
  text-transform: uppercase;
  color: var(--mainColor);
}
.rbc-event {
  font-size: 10px !important;
}

.rbc-show-more {
  background-color: rgb(255 255 255 / 0%) !important;
  color: #606060 !important;
}

/*Time Selector*/
.react-datepicker__time-container {
  width: 30vw !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100% !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected:hover {
  background-color: var(--mainColor);
}

/*Row Override*/
.row {
  margin: 0;
}

/*Toggle overide*/
.custom-control-input:checked ~ .custom-control-label::before,
.custom-toggle .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

/*Alert Override*/
.alert {
  font-size: small;
  padding: 0.5rem;
  border-radius: 0.375rem;
}

/*Modal Override*/
.modal-backdrop.show {
  opacity: 0.8;
}

.booking-form-modal {
  width: 80vw !important;
}

@media only screen and (max-width: 800px) {
  .booking-form-modal {
    width: 95vw !important;
  }
}

/*Print Itinerary*/

.check-left-line {
  border-left: 1px dashed var(--mainColor);
  position: absolute;
  height: 55%;
  top: 30px;
  left: 12px;
}

.itin-details {
  display: inline-block;
  border-left: 1px dashed;
  padding-left: 10px;
  margin-left: -20px;
}
.print-itinerary {
  color: var(--mainColor);
  font-size: 10px;
  list-style: none;
  padding-left: 0;
}
.print-itinerary li {
  margin-bottom: 30px;
  animation: 1s ease-in 0s 1 slideInFromTop;
}
.print-itinerary li:before {
  content: "⬤";
  padding-right: 15px;
}
.print-itinerary li:nth-child(1) {
  animation-delay: 0s;
}
.print-itinerary li:nth-child(2) {
  animation-delay: 1s;
}
.print-itinerary li:nth-child(3) {
  animation-delay: 2s;
}

.review-content > p {
  font-size: 12px;
  margin: 0 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.cover-photo > img {
  width: 100% !important;
  height: auto !important;
}

.blog-card:hover {
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.15) !important;
}

.pagination > ul {
  display: inline-block !important;
  padding: 0 !important;
}

.pagination > ul > li {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  list-style-type: none;
}

.pagination > ul > li.selected {
  background-color: var(--mainColor);
  color: white;
  border: 1px solid var(--mainColor);
}

#adgshp-29038099 {
  margin: 0 auto !important;
}

.badge-notif {
  position: absolute !important;
  /* top: 0px; */
  right: -3px !important;
  font-size: 0.8em !important;
  background-color: var(--mainColor) !important;
}

.pagination > ul > li {
  padding: 3px 7px;
  margin-right: 5px;
  background-color: #fff;
}

.pagination > ul > li > a {
  text-decoration: none;
  color: var(--mainColor);
}

.active-page {
  background-color: var(--mainColor) !important;
  color: #ddd !important;
}

.active-page > a {
  color: #ddd !important;
}
.main-header-menu-link > a {
  display: flex !important;
  flex-direction: column !important;
}

.main-header-menu-collapse {
  display: inline-block !important;
  width: 100%;
}
