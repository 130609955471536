.has-fixed-layout {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.has-fixed-layout td,
.has-fixed-layout th {
  border: 1px solid #ddd;
  padding: 8px;
}

.has-fixed-layout tr:nth-child(even) {
  background-color: #f2f2f2;
}

.has-fixed-layout tr:hover {
  background-color: #ddd;
}

.has-fixed-layout th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--mainColor);
  color: white;
}

.blog-image > img {
    width: 100% !important;
}